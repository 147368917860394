.root {
    color: var(--text-color);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    padding: 3rem;
    background: #fff;
    box-shadow: 0 8px 24px rgba(138, 141, 253, 0.1);
    border-radius: 6px;
    text-align: center;
}

@media (min-width: 1240px) {
    .root {
        padding: 6rem;
    }
}

.content,
.footer {
    position: relative;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;
}

.header {
    padding-bottom: 1rem;
}

.footer {
    margin-top: 3rem;
}