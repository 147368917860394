.title {
    font-weight: 400;
    font-size: 1.6rem;
    color: #F2F2FC;
}

.title b {
    color: #fff;
    padding: 0 0 0 .5rem;
}

.item {
    padding-right: 1.5rem;
    width: 20rem;
}

.item:nth-child(n+1) ::-webkit-progress-value{
    background: #F5AF5C;
}

.item:nth-child(n+2) ::-webkit-progress-value{
    background: #5DB1FF;
}

.progressTitle {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    flex-basis: 100%;
    color: #fff;
    font-size: 1.2rem;
    padding-bottom: .5rem;
    font-weight: 400;
}

.progressTitle span:first-child {
    padding: 0 1rem 0 0;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

progress {
    display: block;
    width: 100%;
    border: 0 none;
    background: var(--color-gray-3);
    border-radius: 3px;
}
progress::-moz-progress-bar {
    border-radius: 3px;
    background: #FFF;

}
/* webkit */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    progress {
        height: 6px;
    }
}
progress::-webkit-progress-bar {
    background: transparent;
}
progress::-webkit-progress-value {
    border-radius: 3px;
    background: #FFF;
}