.summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    gap: 60px;
}

.scoreBlock {
    display: flex;
    width: 150px;
    min-width: 150px;
    height: 150px;
}

.scoreBlock .inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 34px;
    line-height: 51px;
}

.of {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray);
}

.preloaderWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preloaderWrap > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray);
}

.detailsBlock {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray);
}

.detailsBlock .icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    display: inline-flex;
}

.detailsBlock .item {
    display: flex;
}

.detailsBlock .item:not(:first-of-type) {
    margin-top: 10px;
}

.detailsBlock .title:after {
    content: ':';
}

.detailsBlock .value {
    color: var(--text-color);
    margin-left: 12px;
}

@media (max-width: 991px) {
    .detailsBlock {
        gap: 30px;
    }
}

.message {
    display: inline-block;
    margin-top: 2rem;
    padding: .8rem 1.6rem;
    background: #ffedd3;
    font-weight: 400;
    font-size: 1.2rem;
    border-radius: 8px;
    color: var(--text-color);
}
