.root {
    background: var(--primary-color);
    flex-basis: 100%;
    padding: 1.35rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button {
    font-size: 1.2rem;
    padding: .8rem 2rem;
}

@media (min-width: 767px) {
   .root {
       padding: 1.35rem 6rem;
   }
}

@media (min-width: 1440px) {
    .root {
        justify-content: stretch;
    }
}

.logo {
    display: flex;
    margin: 0 6% 0 0;
}

.logo span {
    font-weight: 600;
    text-align: center;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 1440px) {
    .container {
        flex-grow: 1;
        padding: 0 6rem;
    }
}

.planInfoBar {
    display: none;
}

@media (min-width: 1440px) {
    .planInfoBar {
        display: flex;
    }
}
