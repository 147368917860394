.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-gray);
  margin-bottom: 60px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
