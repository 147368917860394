.inputBlock {
  display: flex;
  margin-bottom: 20px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.btn {
  white-space: nowrap;
}

.label {
  flex-grow: 1;
  display: flex;
}

.input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
  min-width: 20%;
}

.inputError {
  border-color: var(--color-text-error);
}

.error {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  background: var(--color-error);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.error > span {
  padding-left: 10px;
}

@media (min-width: 991px) {
  .input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0;
  }

  .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

@media (max-width: 991px) {
  .wrap {
    flex-direction: column;
  }
  .input {
    width: 100%;
  }
  .btn {
    margin-top: 10px;
  }
}
