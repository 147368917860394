.wrapper {
  overflow: auto;
  width: auto;
  position: relative;
}

.inner {
  min-width: 420px;
}

.table {
  width: 100%;
}

.titles,
.item {
  display: grid;
  align-items: center;
  grid-template-columns: 0.4fr 3fr 1.2fr 0.8fr;
  grid-column-gap: 30px;
  font-size: 14px;
}

.item {
  color: var(--text-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.title,
.link {
  font-weight: 500;
}

.link {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
  color: var(--link-color);
}

.back {
  gap: 15px;
  margin-left: auto;
}

.row {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  border-radius: 6px;
}

.firstCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lastCell {
  text-align: right;
  padding-right: 15px;
}

.critical {
  background: var(--color-error);
}

.critical .icon {
  background-image: url("../../assets/icons/error.svg");
}

.warning {
  background: var(--color-warning);
}

.warning .icon {
  background-image: url("../../assets/icons/warning.svg");
}

.notice {
  background: var(--color-notification);
}

.notice .icon {
  background-image: url("../../assets/icons/notification.svg");
}

.details {
  margin-top: 15px;
}

.details > li {
  padding: 15px;
  background: var(--color-error);
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.details > li:not(:first-of-type) {
  margin-top: 15px;
}

.detailsTitle {
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

@media (max-width: 640px) {
  .titles,
  .item {
    grid-column-gap: 10px;
  }
}
