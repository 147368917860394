.root {
    composes: base root from '../index.module.css';
    border-radius: 3px;
}

.transparent {
    background: transparent;
    border-color: transparent;
    color: var(--primary-color)
}

.white {
    background: #fff;
    color: var(--primary-color);
}

.white:hover {
    filter: brightness(85%);
}

.blue {
    background: var(--primary-color);
    color: #fff
}

.blue:hover {
    filter: brightness(85%);
}
