.root {
  border-radius: 50px;
  border: 1px solid transparent;
  transition: all 0.3s;
  cursor: pointer;
}

.root:disabled {
  pointer-events: none;
  background: transparent;
}

.base {
  position: relative;
  width: fit-content;
  font-weight: 600;
  padding: .6rem 3.2rem;
  font-size: 1.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.11rem;
}

.base:disabled {
  color: #bbb;
}
