.title {
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-gray);
  margin-bottom: 60px;
}

.wrapper ol {
  font-size: 16px;
}

.firstLevel {
  margin: 15px 0 0;
}

.firstLevel > li:not(:first-of-type) {
  margin-top: 30px;
}

.back {
  gap: 15px;
}
