@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --primary-color: #6c6fef;
  --text-color: #1c1b4f;

  --heading-color: var(--color-gray-3);
  --primary-color-light: #8184f8;
  --primary-color-dark: #5a5ded;
  --color-text-error: #dd4d4d;
  --highlight-color: #d13267;
  --bg-color: #f8fafb;
  --block-border-radius: 16px;
  --link-color: #3d86fe;

  --color-gray: #878aa1;
  --color-gray-2: #e3e5f3;
  --color-gray-3: #f2f2fc;
  --color-gray-4: #f8fafb;

  --color-error: #fbf8f8;
  --color-warning: #fbfaf8;
  --color-notification: #f8fafb;

  --header-size: 6.5rem;
}

* {
    font-family: Poppins, sans-serif;
    box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  background: var(--bg-color);
  font-size: 1.6rem;
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  color: var(--link-color);
}
