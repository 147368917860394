#root {
  display: flex;
  flex-wrap: wrap;
}

.page-container {
  flex-grow: 1;
  width: calc(100% - 300px);
  padding: var(--header-size) 6rem 6rem;
}

.page-block {
  background: #ffffff;
  box-shadow: 8px 8px 24px rgba(109, 112, 240, 0.1);
  border-radius: 16px;
  padding: 60px;
  position: relative;
}

.page-container > .page-block:not(:first-of-type) {
  margin-top: 30px;
}

.page-title {
  font-size: 1em;
  color: var(--text-color);
  display: inline-block;
}

.btn {
  width: max-content;
  display: inline-flex;
  padding: 8px 30px;
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  text-decoration: none;
  background: var(--primary-color);
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  white-space: nowrap;
}

.btn:hover {
  color: #fff;
  background-color: var(--primary-color-light);
}

.btn:active {
  color: #fff;
  background-color: var(--primary-color-dark);
}

.btn.-disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.btn-clear {
  width: max-content;
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--color-gray);
  cursor: pointer;
  border: 0;
  text-decoration: none;
  background: transparent;
  border-radius: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
}

.btn-clear:hover,
.btn-clear:active {
  filter: brightness(1.2);
}

/* forms */
input,
textarea {
  padding: 8px 10px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e3e5f3;
  border-radius: 3px;
}
input::placeholder {
  color: var(--color-gray);
}
textarea {
  min-height: 160px;
}
.error {
  color: var(--color-text-error);
  background: var(--color-error);
  border: 1px solid #fbc9c9;
  border-radius: 10px;
  padding: 8px 15px;
  margin: 10px 0;
  transition: all 0.2s ease-in-out;
}

.error.-no-subscription {
  color: #3c729a;
  background: #f1fcff;
  border-color: #449be8;
}

.error.-no-subscription > span {
  text-decoration: underline;
}

.loader-mask {
  background: #fff;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

code {
  background: #f2f2fc;
  padding: 5px;
  border: 1px solid;
  display: inline-flex;
  border-radius: 3px;
  color: #484854;
  font-size: 14px;
  width: max-content;
  margin: 3px;
}

@media (max-width: 767px) {
  .page-container {
    padding: var(--header-size) 2rem 2rem;
  }

  .page-block {
    padding: 20px;
  }
}

.hidden { display:none; }
