.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  color: #878aa1;
  font-size: 13px;
}

.info {
  line-height: 22px;
}

.arrows {
  display: flex;
  align-items: center;
  margin-left: 36px;
}

.arrow,
.arrow svg {
  height: 18px;
}

.arrow {
  cursor: pointer;
}

.arrow:hover {
  opacity: 0.8;
}

.active svg path {
  fill: #878aa1;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.disabled svg path {
  fill: #e3e5f3;
}

.leftArr {
}

.rightArr {
  transform: rotate(180deg);
  margin-left: 40px;
}
