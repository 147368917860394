.listWrap {
  margin-top: 60px;
}

.list > li {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  background: var(--color-notification);
}

.content {
  flex-wrap: wrap;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.list > li > span {
  font-size: 16px;
  max-width: 50%;
  width: 100%;
}

.code {
  width: 265px;
  max-width: 50%;
}

.code > input {
  font-size: 13px;
  padding: 3px 5px;
}

.actions {
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
}

.actions > button {
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  line-height: 1;
  font-weight: 300;
  background: #fff;
  color: var(--text-color);
}

.head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  word-break: break-word;
  gap: 20px;
}

.title {
  font-size: 1.4rem;
  color: var(--text-color);
}

.back {
  gap: 15px;
}

.statusBlock {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray);
  margin-left: auto;
}

.status {
  padding: 3px 5px;
  border-radius: 4px;
  margin: 0 10px;
}

.link {
  font-size: 16px;
}

:global .finished > .status {
  background: #d5f0d5;
  color: #6f976f;
}

:global .pending > .status {
  background: #ead8b5;
  color: #9d8a65;
}
